@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
}

body {
  display: flex;
  justify-content: center;
  background-color: #201e2a;
  overflow-x: hidden;
}